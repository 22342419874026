import { GetBotDetailsResponse as BotRegistryGetBotDetailsResponse } from '@/api/botRegistryApi/getBotDetails';
import { GetBotDetailsResponse as RedditIntegrationGetBotDetailsResponse } from '@/api/redditIntegrationApi/getBotDetails';

export type BotDetails =
  BotRegistryGetBotDetailsResponse &
  {
    redditIntegration: RedditIntegrationGetBotDetailsResponse | null
  };

export type BotSearchResult = {
  id: string
  name: string
}

export type BotifierStoreState = {
  userId: string,
  accessToken: {
    value: string
    expiresAtUtc: number,
  } | null,
  refreshToken: string,
  isRefreshingToken: boolean,
  lastAccessTokenReceivedAt: Date,
  botSearchResults: BotSearchResult[],
  currentBotDetails?: BotDetails,
  currentBotDetailsLoading: boolean,
  botCreationLoading: boolean
  botListLoading: boolean
}

export const botifierState : BotifierStoreState = {
  accessToken: null,
  refreshToken: '',
  isRefreshingToken: false,
  lastAccessTokenReceivedAt: new Date(0),
  userId: '',
  botSearchResults: [],
  currentBotDetailsLoading: false,
  botCreationLoading: false,
  botListLoading: false,
};
