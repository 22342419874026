import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

const connection = new HubConnectionBuilder()
  // .withUrl('http://localhost:5234/eventHub')
  .withUrl('https://dev.frontendevents.botifier.io/eventHub')
  .build();

export async function register(userId: string) {
  if (connection.state === HubConnectionState.Disconnected)
    await connection.start();

  await connection.invoke('Register', userId);
}

export function setPermissionsChangedEventListener(callback: () => void) {
  connection.on('PermissionsChanged', data => {
    callback();
  });
}
