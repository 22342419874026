import { axiosBotregistry } from '@/axios';
import { SearchBotsResponse } from '@/api/botRegistryApi/searchBots';
import { GetBotDetailsResponse } from '@/api/botRegistryApi/getBotDetails';
import { CreateBotResponse } from '@/api/botRegistryApi/createBot';

export const botRegistryApi = {
  searchBots: async (userId: string): Promise<SearchBotsResponse> => (await axiosBotregistry.get(`botOwners/${userId}/bots`)).data,
  getBotDetails: async (userId: string, botId: string): Promise<GetBotDetailsResponse> => (await axiosBotregistry.get(`botOwners/${userId}/bots/${botId}`)).data,
  createBot: async (botName: string): Promise<CreateBotResponse> => (await axiosBotregistry.post('bots', { name: botName })).data,
};
