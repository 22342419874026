import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import router from '@/router';
import { Store } from 'vuex';
import { BotifierStoreState } from '@/store/state';
import { ActionTypes } from '@/store/action-types';

const axiosAuthent = axios.create({
  baseURL: 'https://dev.auth.botifier.io',
  // baseURL: 'https://localhost:7041',
});

const axiosBotregistry = axios.create({
  baseURL: 'https://dev.botregistry.botifier.io',
  // baseURL: 'https://localhost:7267',
});

const axiosRedditIntegration = axios.create({
  baseURL: 'https://dev.reddit-integration.botifier.io',
  // baseURL: 'https://localhost:7121',
});

const setupAxiosInterceptors = (store: Store<any>) => {
  const authorizationHeaderInterceptor = async (request: AxiosRequestConfig) => {
    const accessToken = (store.state as BotifierStoreState)?.accessToken;

    if (accessToken === null)
      return request;

    const fiveMinutesLaterFromNow = new Date(Date.now() + 5 * 60 * 1000);
    const accessTokenExpirationDate = new Date(accessToken.expiresAtUtc * 1000);
    if (accessTokenExpirationDate < fiveMinutesLaterFromNow) {
      await store.dispatch(ActionTypes.LOGIN_FROM_REFRESH_TOKEN);
    }

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${(store.state as BotifierStoreState).accessToken!.value}`;
    return request;
  };

  const unauthorizedResponseInterceptor = (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      router.go(0);
    }

    return Promise.reject(error);
  };

  axiosBotregistry.interceptors.request.use(authorizationHeaderInterceptor);
  axiosBotregistry.interceptors.response.use(response => response, unauthorizedResponseInterceptor);

  axiosRedditIntegration.interceptors.request.use(authorizationHeaderInterceptor);
  axiosRedditIntegration.interceptors.response.use(response => response, unauthorizedResponseInterceptor);
};

export {
  axiosAuthent,
  axiosBotregistry,
  axiosRedditIntegration,
  setupAxiosInterceptors,
};
