import VueX, {
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import Vue from 'vue';
import { BotifierStoreState, botifierState } from './state';
import { Getters, getters } from './getters';
import { Mutations, mutations } from './mutations';
import { Actions, actions } from './actions';

Vue.use(VueX);

export const store = new VuexStore({
  state: botifierState,
  getters,
  mutations,
  actions,
});

export type BotifierStore = Omit<
  VuexStore<BotifierStoreState>,
  'getters' | 'commit' | 'dispatch'
  > & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
}

export function useStore() {
  return store as BotifierStore;
}
