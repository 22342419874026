import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: () => import('../views/HomeView.vue') },
  { path: '/login', name: 'loginOrSignup', component: () => import('../views/LoginOrSignupView.vue') },
  { path: '/bots/:botId', name: 'botEdit', props: true, component: () => import('../views/BotEditView.vue') },
  { path: '/redditloginredirect', name: 'redditloginredirect', component: () => import('../views/RedditLoginRedirectView.vue') },
  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

type jwtToken = {
  exp: string;
}

router.beforeEach((to, from, next) => {
  if (to.name === 'loginOrSignup') {
    next();
    return;
  }

  const token = localStorage.getItem('accessToken');
  if (token && Date.now() < 1000 * parseInt(jwtDecode<jwtToken>(token).exp, 10)) {
    next();
    return;
  }

  const nextRouteQuery: Record<string, string> = {};
  if (to.name !== 'home')
    nextRouteQuery.redirect = to.fullPath;
  next({ name: 'loginOrSignup', query: nextRouteQuery });
});

export default router;
