export enum ActionTypes {
  WAIT_FOR_NEW_PERMISSIONS = 'WAIT_FOR_NEW_PERMISSIONS',
  ON_PERMISSIONS_CHANGED = 'ON_PERMISSIONS_CHANGED',
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  LOGIN_FROM_REFRESH_TOKEN = 'LOGIN_FROM_REFRESH_TOKEN',
  LOGOUT = 'LOGOUT',
  SEARCH_BOTS = 'SEARCH_BOTS',
  FETCH_BOT_DETAILS = 'FETCH_BOT_DETAILS',
  CREATE_BOT = 'CREATE_BOT',
  CREATE_NEW_REDDIT_TRIGGER = 'CREATE_NEW_REDDIT_TRIGGER',
  DELETE_REDDIT_TRIGGER = 'DELETE_REDDIT_TRIGGER',
  CREATE_NEW_REDDIT_WEBHOOK = 'CREATE_NEW_REDDIT_WEBHOOK',
  DELETE_REDDIT_WEBHOOK = 'DELETE_REDDIT_WEBHOOK',
  CONNECT_REDDIT_ACCOUNT = 'CONNECT_REDDIT_ACCOUNT',
  ENABLE_REDDIT_INTEGRATION_FOR_CURRENT_BOT = 'ENABLE_REDDIT_INTEGRATION_FOR_CURRENT_BOT',
}

export enum CreateBotActionErrorResult {
  BOT_NAME_EXISTS = 'BOT_NAME_EXISTS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type CreateBotActionResult = string | CreateBotActionErrorResult;

export enum CreateNewPostInSubredditTriggerActionErrorResult {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type CreateNewPostInSubredditTriggerActionResult = null | CreateNewPostInSubredditTriggerActionErrorResult;

export enum DeletePostInSubredditTriggerActionErrorResult {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type DeletePostInSubredditTriggerActionResult = null | DeletePostInSubredditTriggerActionErrorResult;

export enum CreateWebhookActionErrorResult {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type CreateWebhookActionResult = null | CreateWebhookActionErrorResult;

export enum DeleteWebhookActionErrorResult {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type DeleteWebhookActionResult = null | DeleteWebhookActionErrorResult;

export enum ConnectRedditAccountErrorResult {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type ConnectRedditAccountResult = null | ConnectRedditAccountErrorResult;

export enum EnableRedditIntegrationErrorResult {
  NO_BOT_CURRENTLY_SELECTED = 'NO_BOT_CURRENTLY_SELECTED',
  FORBIDDEN = 'FORBIDDEN',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}
export type EnableRedditIntegrationResult = null | EnableRedditIntegrationErrorResult;
