export enum MutationTypes {
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_IS_REFRESHING_TOKEN = 'SET_IS_REFRESHING_TOKEN',
  SET_BOT_SEARCH_RESULTS = 'SET_BOT_SEARCH_RESULTS',
  SET_CURRENT_BOT_DETAILS = 'SET_CURRENT_BOT_DETAILS',
  SET_CURRENT_BOT_DETAILS_LOADING = 'SET_CURRENT_BOT_DETAILS_LOADING',
  SET_BOT_CREATION_LOADING = 'SET_BOT_CREATION_LOADING',
  SET_BOT_LIST_LOADING = 'SET_BOT_LIST_LOADING',
  SET_REDDIT_INTEGRATION_DETAILS = 'SET_REDDIT_INTEGRATION_DETAILS',
}
