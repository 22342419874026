import { axiosRedditIntegration } from '@/axios';
import { GetBotDetailsResponse } from '@/api/redditIntegrationApi/getBotDetails';

export const redditIntegrationApi = {
  getBotDetails: async (botId: string): Promise<GetBotDetailsResponse | null> => {
    const response = await axiosRedditIntegration.get(`bots/${botId}`, { validateStatus: status => (status >= 200 && status < 300) || status === 404 });
    return response.status === 404 ? null : response.data;
  },
  createBot: async (botId: string): Promise<void> => (await axiosRedditIntegration.post(`bots/${botId}`)).data,
  createNewTrigger: (botId: string, name: string, type: string): Promise<void> => axiosRedditIntegration.post(`/bots/${botId}/triggers/${type}`, { name }),
  deleteTrigger: (botId: string, triggerId: string): Promise<void> => axiosRedditIntegration.delete(`/bots/${botId}/triggers/${triggerId}`),
  createWebhook: (botId: string, triggerId: string, webhookName: string, webhookUrl: string): Promise<void> => axiosRedditIntegration.post(`/bots/${botId}/triggers/${triggerId}/webhooks`, { name: webhookName, url: webhookUrl }),
  connectRedditAccount: (botId: string, authCode: string): Promise<void> => axiosRedditIntegration.put(`/bots/${botId}/redditAuthCode/${authCode}`),
  deleteWebhook: (botId: string, triggerId: string, webhookId: string): Promise<void> => axiosRedditIntegration.delete(`/bots/${botId}/triggers/${triggerId}/webhooks/${webhookId}`),
};
