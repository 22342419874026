









































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BotifierStore, useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';

@Component({
  components: {},
})
export default class App extends Vue {
  private store: BotifierStore = useStore();

  private logout() {
    this.store.dispatch(ActionTypes.LOGOUT, undefined);
    this.$router.push({ name: 'loginOrSignup' });
  }
}
