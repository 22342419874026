import { ActionTree, ActionContext } from 'vuex';
import { authenticationApi } from '@/api/authenticationApi';
import { botRegistryApi } from '@/api/botRegistryApi';
import { redditIntegrationApi } from '@/api/redditIntegrationApi';
import { AxiosError } from 'axios';
import { register } from '@/eventHub/eventHub';
import { BotDetails, BotSearchResult, botifierState, BotifierStoreState } from './state';
import { Mutations } from './mutations';
import {
  ActionTypes,
  ConnectRedditAccountErrorResult,
  ConnectRedditAccountResult,
  CreateBotActionErrorResult,
  CreateBotActionResult,
  CreateNewPostInSubredditTriggerActionErrorResult,
  CreateNewPostInSubredditTriggerActionResult,
  CreateWebhookActionErrorResult,
  CreateWebhookActionResult,
  DeletePostInSubredditTriggerActionErrorResult,
  DeletePostInSubredditTriggerActionResult,
  DeleteWebhookActionErrorResult,
  DeleteWebhookActionResult,
  EnableRedditIntegrationErrorResult,
  EnableRedditIntegrationResult,
} from './action-types';
import { MutationTypes } from './mutation-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<BotifierStoreState, BotifierStoreState>, 'commit'>

export interface Actions {
  [ActionTypes.ON_PERMISSIONS_CHANGED](context: AugmentedActionContext): Promise<void>,
  [ActionTypes.WAIT_FOR_NEW_PERMISSIONS](context: AugmentedActionContext): Promise<void>,
  [ActionTypes.LOGIN](context: AugmentedActionContext, credentials: {email: string, password: string}): Promise<void>,
  [ActionTypes.SIGNUP](context: AugmentedActionContext, credentials: {email: string, password: string}): Promise<void>,
  [ActionTypes.LOGIN_FROM_REFRESH_TOKEN](context: AugmentedActionContext): Promise<void>,
  [ActionTypes.LOGOUT](context: AugmentedActionContext): Promise<void>,
  [ActionTypes.SEARCH_BOTS](context: AugmentedActionContext): Promise<void>,
  [ActionTypes.FETCH_BOT_DETAILS](context: AugmentedActionContext, botId: string): Promise<void>,
  [ActionTypes.CREATE_BOT](context: AugmentedActionContext, newBotName: string): Promise<CreateBotActionResult>,
  [ActionTypes.CREATE_NEW_REDDIT_TRIGGER](context: AugmentedActionContext, payload: { name: string, type: string }): Promise<CreateNewPostInSubredditTriggerActionResult>,
  [ActionTypes.DELETE_REDDIT_TRIGGER](context: AugmentedActionContext, payload: { id: string }): Promise<DeletePostInSubredditTriggerActionResult>,
  [ActionTypes.CREATE_NEW_REDDIT_WEBHOOK](context: AugmentedActionContext, payload: { triggerId: string, webhookName: string, webhookUrl: string }): Promise<CreateWebhookActionResult>,
  [ActionTypes.DELETE_REDDIT_WEBHOOK](context: AugmentedActionContext, payload: { triggerId: string, webhookId: string }): Promise<DeleteWebhookActionResult>,
  [ActionTypes.CONNECT_REDDIT_ACCOUNT](context: AugmentedActionContext, payload: { botId: string, authCode: string }): Promise<ConnectRedditAccountResult>,
  [ActionTypes.ENABLE_REDDIT_INTEGRATION_FOR_CURRENT_BOT](context: AugmentedActionContext): Promise<EnableRedditIntegrationResult>,
}

function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const actions: ActionTree<BotifierStoreState, BotifierStoreState> & Actions = {

  async [ActionTypes.ON_PERMISSIONS_CHANGED](context) {
    await context.dispatch(ActionTypes.LOGIN_FROM_REFRESH_TOKEN);
    // await context.dispatch(ActionTypes.LOGIN, { email: 'comte.florian@gmail.com', password: 'Toto12345+' });
  },

  async [ActionTypes.LOGIN_FROM_REFRESH_TOKEN](context) {
    if (context.state.isRefreshingToken)
      return;

    context.commit(MutationTypes.SET_IS_REFRESHING_TOKEN, true);

    const { accessToken, refreshToken } = await authenticationApi.loginFromRefreshToken({ expiredAccessToken: context.state.accessToken!.value, refreshToken: context.state.refreshToken });
    await localStorage.setItem('accessToken', accessToken);
    await localStorage.setItem('refreshToken', refreshToken);
    context.commit(MutationTypes.SET_ACCESS_TOKEN, accessToken);
    context.commit(MutationTypes.SET_REFRESH_TOKEN, refreshToken);
    await register(botifierState.userId);

    context.commit(MutationTypes.SET_IS_REFRESHING_TOKEN, false);
  },

  async [ActionTypes.WAIT_FOR_NEW_PERMISSIONS](context) {
    const startedWaitingAt = new Date(Date.now());
    let waitedFor = 0;
    while (startedWaitingAt > context.state.lastAccessTokenReceivedAt && waitedFor < 10_000) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(100);
      waitedFor += 1;
    }
  },

  async [ActionTypes.LOGIN](context, { email, password }) {
    const { accessToken, refreshToken } = await authenticationApi.login({ email, password });
    await localStorage.setItem('accessToken', accessToken);
    await localStorage.setItem('refreshToken', refreshToken);
    context.commit(MutationTypes.SET_ACCESS_TOKEN, accessToken);
    context.commit(MutationTypes.SET_REFRESH_TOKEN, refreshToken);
    await register(botifierState.userId);
  },

  async [ActionTypes.SIGNUP](context, { email, password }) {
    const { accessToken, refreshToken } = await authenticationApi.signup({ email, password });
    await context.dispatch(ActionTypes.LOGIN, { email, password });
  },

  [ActionTypes.LOGOUT](context) {
    localStorage.setItem('accessToken', '');
    localStorage.setItem('refreshToken', '');
    context.commit(MutationTypes.SET_ACCESS_TOKEN, '');
    return Promise.resolve();
  },

  async [ActionTypes.SEARCH_BOTS](context) {
    context.commit(MutationTypes.SET_BOT_LIST_LOADING, true);
    const bots: BotSearchResult[] = await botRegistryApi.searchBots(context.state.userId);
    context.commit(MutationTypes.SET_BOT_SEARCH_RESULTS, bots);
    context.commit(MutationTypes.SET_BOT_LIST_LOADING, false);
  },

  async [ActionTypes.FETCH_BOT_DETAILS](context, botId) {
    context.commit(MutationTypes.SET_CURRENT_BOT_DETAILS_LOADING, true);
    const botDetailsFromBotRegistry = botRegistryApi.getBotDetails(context.state.userId, botId);
    const redditIntegrationDetails = redditIntegrationApi.getBotDetails(botId);
    const botDetails : BotDetails = {
      ...await botDetailsFromBotRegistry,
      redditIntegration: await redditIntegrationDetails,
    };
    context.commit(MutationTypes.SET_CURRENT_BOT_DETAILS, botDetails);
    context.commit(MutationTypes.SET_CURRENT_BOT_DETAILS_LOADING, false);
  },

  async [ActionTypes.CREATE_BOT](context, newBotName) {
    context.commit(MutationTypes.SET_BOT_CREATION_LOADING, true);
    try {
      return (await botRegistryApi.createBot(newBotName)).id;
    } catch (e: unknown) {
      if ((e as AxiosError)?.response?.status === 409)
        return CreateBotActionErrorResult.BOT_NAME_EXISTS;
      return CreateBotActionErrorResult.UNKNOWN_ERROR;
    } finally {
      context.commit(MutationTypes.SET_BOT_CREATION_LOADING, false);
    }
  },

  async [ActionTypes.CREATE_NEW_REDDIT_TRIGGER](context, { name, type }) {
    try {
      if (context.state.currentBotDetails === undefined)
        return CreateNewPostInSubredditTriggerActionErrorResult.UNKNOWN_ERROR;
      await redditIntegrationApi.createNewTrigger(context.state.currentBotDetails.id, name, type);
      const redditIntegration = await redditIntegrationApi.getBotDetails(botifierState.currentBotDetails!.id);
      context.commit(MutationTypes.SET_REDDIT_INTEGRATION_DETAILS, redditIntegration!);
      return null;
    } catch (e: unknown) {
      return CreateNewPostInSubredditTriggerActionErrorResult.UNKNOWN_ERROR;
    }
  },

  async [ActionTypes.DELETE_REDDIT_TRIGGER](context, { id }) {
    try {
      if (context.state.currentBotDetails === undefined)
        return DeletePostInSubredditTriggerActionErrorResult.UNKNOWN_ERROR;
      await redditIntegrationApi.deleteTrigger(context.state.currentBotDetails.id, id);
      const redditIntegration = await redditIntegrationApi.getBotDetails(botifierState.currentBotDetails!.id);
      context.commit(MutationTypes.SET_REDDIT_INTEGRATION_DETAILS, redditIntegration!);
      return null;
    } catch (e: unknown) {
      return DeletePostInSubredditTriggerActionErrorResult.UNKNOWN_ERROR;
    }
  },

  async [ActionTypes.CREATE_NEW_REDDIT_WEBHOOK](context, { triggerId, webhookName, webhookUrl }) {
    try {
      if (context.state.currentBotDetails === undefined)
        return CreateWebhookActionErrorResult.UNKNOWN_ERROR;
      await redditIntegrationApi.createWebhook(context.state.currentBotDetails.id, triggerId, webhookName, webhookUrl);
      const redditIntegration = await redditIntegrationApi.getBotDetails(botifierState.currentBotDetails!.id);
      context.commit(MutationTypes.SET_REDDIT_INTEGRATION_DETAILS, redditIntegration!);
      return null;
    } catch (e: unknown) {
      return CreateWebhookActionErrorResult.UNKNOWN_ERROR;
    }
  },

  async [ActionTypes.DELETE_REDDIT_WEBHOOK](context, { triggerId, webhookId }) {
    try {
      if (context.state.currentBotDetails === undefined)
        return DeleteWebhookActionErrorResult.UNKNOWN_ERROR;
      await redditIntegrationApi.deleteWebhook(context.state.currentBotDetails.id, triggerId, webhookId);
      const redditIntegration = await redditIntegrationApi.getBotDetails(botifierState.currentBotDetails!.id);
      context.commit(MutationTypes.SET_REDDIT_INTEGRATION_DETAILS, redditIntegration!);
      return null;
    } catch (e: unknown) {
      return DeleteWebhookActionErrorResult.UNKNOWN_ERROR;
    }
  },

  async [ActionTypes.CONNECT_REDDIT_ACCOUNT](context, { botId, authCode }) {
    try {
      await redditIntegrationApi.connectRedditAccount(botId, authCode);
      return null;
    } catch (e: unknown) {
      return ConnectRedditAccountErrorResult.UNKNOWN_ERROR;
    }
  },

  async [ActionTypes.ENABLE_REDDIT_INTEGRATION_FOR_CURRENT_BOT](context) {
    try {
      if (!botifierState.currentBotDetails?.id)
        return EnableRedditIntegrationErrorResult.NO_BOT_CURRENTLY_SELECTED;
      await redditIntegrationApi.createBot(botifierState.currentBotDetails?.id);
      const redditIntegration = await redditIntegrationApi.getBotDetails(botifierState.currentBotDetails?.id);
      context.commit(MutationTypes.SET_REDDIT_INTEGRATION_DETAILS, redditIntegration!);
      return null;
    } catch (e: any) {
      if (e.response?.status === 403)
        return EnableRedditIntegrationErrorResult.FORBIDDEN;
      return EnableRedditIntegrationErrorResult.UNKNOWN_ERROR;
    }
  },
};
