import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import DiscordIcon from '@/components/icons/DiscordIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#1b415c',
        // secondary: '#0d1a24',
        secondary: '#494d50',
        background: '#1c1c1c',
        // primary: '#0d1a24',
        // secondary: '#1b415c',
        success: '#5cce91',
        info: '#abdafc',
        reddit: '#ff6314',
        'app-background': '#232628',
        danger: '#ff7d7d',
        'reddit-soft': '#b16b48', // #E53935'
        // accent: colors.indigo.base, // #3F51B5
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      discord: {
        component: DiscordIcon,
      },
    },
  },
});
