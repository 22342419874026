import { GetterTree, MutationTree } from 'vuex';
import { Getters } from '@/store/getters';
import { GetBotDetailsResponse } from '@/api/redditIntegrationApi/getBotDetails';
import { MutationTypes } from './mutation-types';
import { BotDetails, BotSearchResult, BotifierStoreState } from './state';

export type Mutations<S = BotifierStoreState> = {
  [MutationTypes.SET_ACCESS_TOKEN](state: S, accessToken: string): void,
  [MutationTypes.SET_REFRESH_TOKEN](state: S, refreshToken: string): void,
  [MutationTypes.SET_IS_REFRESHING_TOKEN](state: S, isRefreshing: boolean): void,
  [MutationTypes.SET_BOT_SEARCH_RESULTS](state: S, payload: BotSearchResult[]): void,
  [MutationTypes.SET_CURRENT_BOT_DETAILS](state: S, payload: BotDetails): void
  [MutationTypes.SET_CURRENT_BOT_DETAILS_LOADING](state: S, payload: boolean): void
  [MutationTypes.SET_BOT_CREATION_LOADING](state: S, payload: boolean): void
  [MutationTypes.SET_BOT_LIST_LOADING](state: S, payload: boolean): void
  [MutationTypes.SET_REDDIT_INTEGRATION_DETAILS](state: S, payload: GetBotDetailsResponse): void
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export const mutations: MutationTree<BotifierStoreState> & Mutations = {

  [MutationTypes.SET_ACCESS_TOKEN](state, accessToken: string) {
    if (accessToken === '') {
      state.accessToken = null;
      state.userId = '';

      return;
    }

    const tokenParsed = parseJwt(accessToken);
    state.accessToken = {
      value: accessToken,
      expiresAtUtc: tokenParsed.exp,
    };
    state.userId = tokenParsed.UserId;
    state.lastAccessTokenReceivedAt = new Date(Date.now());
  },

  [MutationTypes.SET_REFRESH_TOKEN](state, refreshToken: string) {
    state.refreshToken = refreshToken;
  },

  [MutationTypes.SET_IS_REFRESHING_TOKEN](state, isRefreshing: boolean) {
    state.isRefreshingToken = isRefreshing;
  },

  [MutationTypes.SET_BOT_SEARCH_RESULTS](state, payload: BotSearchResult[]) {
    state.botSearchResults = payload;
  },

  [MutationTypes.SET_CURRENT_BOT_DETAILS](state, payload: BotDetails) {
    state.currentBotDetails = payload;
  },

  [MutationTypes.SET_CURRENT_BOT_DETAILS_LOADING](state, payload: boolean) {
    state.currentBotDetailsLoading = payload;
  },

  [MutationTypes.SET_BOT_CREATION_LOADING](state, payload: boolean) {
    state.botCreationLoading = payload;
  },

  [MutationTypes.SET_BOT_LIST_LOADING](state, payload: boolean) {
    state.botListLoading = payload;
  },

  [MutationTypes.SET_REDDIT_INTEGRATION_DETAILS](state, payload) {
    state.currentBotDetails!.redditIntegration = payload;
  },
};

export const getters: GetterTree<BotifierStoreState, BotifierStoreState> & Getters = {
  userId: () => {
    const token = localStorage.getItem('accessToken');
    return token === null ? '' : parseJwt(token).userId;
  },
};
