import Vue from 'vue';
import { register, setPermissionsChangedEventListener } from '@/eventHub/eventHub';
import { ActionTypes } from '@/store/action-types';
import { setupAxiosInterceptors } from '@/axios';
import App from './App.vue';
import router from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
// import { MutationTypes } from '@/store/mutation-types';

Vue.config.productionTip = false;

setupAxiosInterceptors(store);

setPermissionsChangedEventListener(() => {
  store.dispatch(ActionTypes.ON_PERMISSIONS_CHANGED);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    store.commit('SET_ACCESS_TOKEN', accessToken);
    store.commit('SET_REFRESH_TOKEN', refreshToken);
    if (accessToken)
      register(store.state.userId);
  },
}).$mount('#app');
